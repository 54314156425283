<template>
  <div
    class="py-8 text-center"
    :style="{ color: color, 'background-color': 'transparent' }"
  >
    <span>Pivotad © {{ getCurrentYear }}</span>
    <div class="d-flex align-center justify-center mt-4 social-media-account">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <img
              style="cursor: pointer; border-radius: 50%; margin-top: 7px"
              :style="{ 'background-color': bgColor ? bgColor : '' }"
              :src="twitterLogo"
              width="40"
              height="40"
              class="image"
              @click="clickAccount('https://twitter.com/PivotAd_ai ')"
              alt="twitter logo"
              loading="lazy"
            />
          </span>
        </template>
        <span>Twitter</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <img
              :src="facebookLogo"
              style="cursor: pointer; border-radius: 50%; margin-top: 7px"
              :style="{ 'background-color': bgColor ? bgColor : '' }"
              width="40"
              height="40"
              class="ml-5 image"
              @click="clickAccount('https://www.facebook.com/pivotadai')"
              alt="facebook logo"
              loading="lazy"
            />
          </span>
        </template>
        <span>Facebook</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <img
              :src="instagramLogo"
              style="cursor: pointer; border-radius: 50%; margin-top: 7px"
              :style="{ 'background-color': bgColor ? bgColor : '' }"
              width="40"
              height="40"
              class="ml-5 image"
              @click="clickAccount('https://www.instagram.com/pivotad_ai')"
              alt="instagram logo"
              loading="lazy"
            />
          </span>
        </template>
        <span>Instagram</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <div
              class="background-logo d-flex align-center ml-5"
              style="width: 40px; height: 40px; cursor: pointer; border-radius: 50%"
              :style="{
                'background-color': bgColor,
                opacity: bgColor ? '0.8' : '',
              }"
            >
              <img
                :src="linkedIn"
                width="25"
                height="25"
                class="image"
                style="margin: 0 7px"
                @click="
                  clickAccount('https://www.linkedin.com/company/pivotad')
                "
                alt="LinkedIn logo"
                loading="lazy"
              />
            </div>
          </span>
        </template>
        <span>LinkedIn</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <div
              class="background-logo d-flex align-center ml-5"
              style="width: 40px; height: 40px; cursor: pointer; border-radius: 50%"
              :style="{
                'background-color': bgColor,
                opacity: bgColor ? '0.8' : '',
              }"
            >
              <img
                :src="youtubeLogo"
                width="25"
                height="25"
                class="image"
                style="margin: 0 8px; color: #000"
                @click="
                  clickAccount(
                    'https://www.youtube.com/channel/UCwgLVMlmfrA9Ny0KqoV09rg'
                  )
                "
                alt="youtube logo"
                loading="lazy"
              />
            </div>
          </span>
        </template>
        <span>YouTube</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import { currentDate } from "@/utils";
import facebookLogo from "@/assets/logos/FacebookLogo.svg";
import instagramLogo from "@/assets/logos/InstagramLogo.svg";
import plusLogo from "@/assets/logos/PlusLogo.svg";
import twitterLogo from "@/assets/logos/TwitterLogo.svg";
import youtubeLogo from "@/assets/img/youtube.svg";
import linkedIn from "@/assets/img/linkedIn.svg";

export default {
  name: "Footer",
  props: {
    color: String,
    bgColor: {
      type: String,
    },
  },
  data() {
    return {
      facebookLogo,
      instagramLogo,
      plusLogo,
      twitterLogo,
      youtubeLogo,
      linkedIn,
    };
  },
  methods: {
    clickAccount(url) {
      window.open(url);
    },
  },
  computed: {
    getCurrentYear() {
      return currentDate;
    },
  },
};
</script>

<style scoped lang="scss">
.social-media-account {
  .image {
    cursor: pointer !important;
  }
}
</style>
